import { enableProdMode, importProvidersFrom, isDevMode } from '@angular/core';
import { HammerModule, bootstrapApplication } from '@angular/platform-browser';
import { RouteReuseStrategy, provideRouter, withComponentInputBinding } from '@angular/router';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';
import { defineCustomElements } from '@ionic/pwa-elements/loader';

import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { reducers } from './app/app.reducer';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { AlunoService } from './app/shared/services/aluno.service';
import { AuthInterceptor } from './app/pages/autenticacao/autenticacao.service';

import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { Home, LucideAngularModule, icons } from 'lucide-angular';
import 'hammerjs';
import { EnvironmentLoaderService } from './app/shared/services/environment-loader.service';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getAnalytics } from 'firebase/analytics';


registerLocaleData(localePt);

defineCustomElements(window);
const firebaseConfig = {
  apiKey: "AIzaSyDC0pW8oGJb-Lec1Dfa-IeaMHKdwf9dAf0",
  authDomain: "multfacil-695fd.firebaseapp.com",
  projectId: "multfacil-695fd",
  storageBucket: "multfacil-695fd.appspot.com",
  messagingSenderId: "1041379932994",
  appId: "1:1041379932994:web:23d6e6332caa0c3bb326db",
  measurementId: "G-GD22WZD5FK"
};

const environmentLoader = new EnvironmentLoaderService();
environmentLoader.loadEnvironment().then(() => {
  if (environment.production) {
    enableProdMode();
  }

  bootstrapApplication(AppComponent, {
    providers: [
      provideFirebaseApp(() => initializeApp({ ...firebaseConfig })),
      provideFirestore(() => getFirestore()),
      AlunoService,
      importProvidersFrom(LucideAngularModule.pick(icons), HammerModule),
      provideHttpClient(
        withInterceptorsFromDi(),
      ),
      {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true,
      },
      provideStore(reducers),
      { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
      provideIonicAngular(),
      provideRouter(routes, withComponentInputBinding()),
      provideHttpClient(),
      provideStoreDevtools({
        maxAge: 25, // Retains last 25 states
        logOnly: !isDevMode(), // Restrict extension to log-only mode
        autoPause: true, // Pauses recording actions and state changes when the extension window is not open
        trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
        traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
      }),
      provideAnimationsAsync(),
    ],
  }).catch(err => console.error(err));
});
