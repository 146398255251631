import { CanActivate, Router } from "@angular/router";
import { AutenticacaoService } from "./autenticacao.service";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
}) export class AuthGuard implements CanActivate {
  constructor(private autenticacaoService: AutenticacaoService, private router: Router) { }

  canActivate() {
    if (this.autenticacaoService.isLoggedIn()) {
      //this.autenticacaoService.refreshToken();
      return true;
    } else {
      this.autenticacaoService.logout();
      this.router.navigate(["entrar"]);

      return false;
    }
  }
}
