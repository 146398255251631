import {Injectable} from '@angular/core';
import {
    environment as defaultEnvironment
} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class EnvironmentLoaderService {
    private envConfig: any = defaultEnvironment;

    constructor() {
    }

    loadEnvironment(): Promise<void> {
        return new Promise((resolve) => {
            const url = window.location.href;

            if (url.includes('homolog')) {
                if (url.includes('franco')) {
                    import('../../../environments/environment.francodarocha_hom').then(env => {
                        this.envConfig = env.environment;
                        Object.assign(defaultEnvironment, this.envConfig);
                        resolve();
                    });
                } else if (url.includes('mairipora')) {
                    import('../../../environments/environment.mairipora_hom').then(env => {
                        this.envConfig = env.environment;
                        Object.assign(defaultEnvironment, this.envConfig);
                        resolve();
                    });
                } else if (url.includes('guara')) {
                    import('../../../environments/environment.guaratingueta_hom').then(env => {
                        this.envConfig = env.environment;
                        Object.assign(defaultEnvironment, this.envConfig);
                        resolve();
                    });
                } else if (url.includes('ilhabela')) {
                    import('../../../environments/environment.ilhabela_hom').then(env => {
                        this.envConfig = env.environment;
                        Object.assign(defaultEnvironment, this.envConfig);
                        resolve();
                    });
                } else if (url.includes('educaita')) {
                    import('../../../environments/environment.itanhaem_hom').then(env => {
                        this.envConfig = env.environment;
                        Object.assign(defaultEnvironment, this.envConfig);
                        resolve();
                    });
                } else if (url.includes('mairipora')) {
                    import('../../../environments/environment.mairipora_hom').then(env => {
                        this.envConfig = env.environment;
                        Object.assign(defaultEnvironment, this.envConfig);
                        resolve();
                    });
                } else if (url.includes('nilopolis')) {
                    import('../../../environments/environment.nilopolis_hom').then(env => {
                        this.envConfig = env.environment;
                        Object.assign(defaultEnvironment, this.envConfig);
                        resolve();
                    });
                } else {
                    resolve();
                }
            } else {
                if (url.includes('franco')) {
                    import('../../../environments/environment.francodarocha_prod').then(env => {
                        this.envConfig = env.environment;
                        Object.assign(defaultEnvironment, this.envConfig);
                        resolve();
                    });
                } else if (url.includes('mairipora')) {
                    import('../../../environments/environment.mairipora_prod').then(env => {
                        this.envConfig = env.environment;
                        Object.assign(defaultEnvironment, this.envConfig);
                        resolve();
                    });
                } else if (url.includes('guara')) {
                    import('../../../environments/environment.guaratingueta_prod').then(env => {
                        this.envConfig = env.environment;
                        Object.assign(defaultEnvironment, this.envConfig);
                        resolve();
                    });
                } else if (url.includes('ilhabela')) {
                    import('../../../environments/environment.ilhabela_prod').then(env => {
                        this.envConfig = env.environment;
                        Object.assign(defaultEnvironment, this.envConfig);
                        resolve();
                    });
                } else if (url.includes('educaita')) {
                    import('../../../environments/environment.itanhaem_prod').then(env => {
                        this.envConfig = env.environment;
                        Object.assign(defaultEnvironment, this.envConfig);
                        resolve();
                    });
                } else if (url.includes('mairipora')) {
                    import('../../../environments/environment.mairipora_prod').then(env => {
                        this.envConfig = env.environment;
                        Object.assign(defaultEnvironment, this.envConfig);
                        resolve();
                    });
                } else if (url.includes('nilopolis')) {
                    import('../../../environments/environment.nilopolis_prod').then(env => {
                        this.envConfig = env.environment;
                        Object.assign(defaultEnvironment, this.envConfig);
                        resolve();
                    });
                } else {
                    resolve();
                }
            }
        });
    }

    getEnvironment() {
        return this.envConfig;
    }
}
