import {
    CUSTOM_ELEMENTS_SCHEMA,
    Component,
    EnvironmentInjector,
    OnInit
} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {
    IonApp,
    IonRouterOutlet,
    IonTabs,
    IonSplitPane
} from '@ionic/angular/standalone';
import {environment} from 'src/environments/environment';
import {register} from 'swiper/element/bundle';
import {ThemeService} from './shared/services/theme.service';

register();

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    standalone: true,
    imports: [IonTabs, IonApp, IonRouterOutlet, IonSplitPane],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppComponent implements OnInit {
    public team = environment.team;
    title = "Portal do Aluno";
    public isDarkMode = false;

    constructor(public environmentInjector: EnvironmentInjector, private titleService: Title, private themeService: ThemeService) {
    }

    ngOnInit() {
        this.updateFavIconAndTitle();
        this.themeService.darkMode$.subscribe(darkMode => {
            this.isDarkMode = darkMode;
            this.applyTheme();
        });
    }

    applyTheme() {
        const body = document.body;
        if (this.isDarkMode) {
            body.classList.add('dark');
        } else {
            body.classList.remove('dark');
        }
    }

    toggleDarkMode() {
        this.themeService.toggleDarkMode();
    }

    updateFavIconAndTitle() {
        const favIcon: HTMLLinkElement = document.querySelector("#appIcon") as HTMLLinkElement;
        if (!favIcon) return;

        switch (this.team) {
            case 'mairipora':
                favIcon.href = "assets/img/favicon-mairipora/favicon.ico";
                this.titleService.setTitle("EducaSim - Portal do Aluno");
                break;
            case 'francodarocha':
                favIcon.href = "assets/img/favicon-franco/favicon.ico";
                this.titleService.setTitle("EducaFranco - Portal do Aluno");
                break;
            case 'nilopolis':
                favIcon.href = 'assets/img/favicon-nilopolis/favicon.ico';
                this.titleService.setTitle('Nilópolis Educa - Portal do Estudante');
                break;
            case 'guaratingueta':
                favIcon.href = 'assets/img/favicon-guara/favicon.ico';
                this.titleService.setTitle('Escola Online - Portal do Aluno');
                break;

            default:
                favIcon.href = "assets/img/favicon.ico";
                this.titleService.setTitle("EducaFácil - Portal do Aluno");
                break;
        }

        favIcon.href = "assets/icon/favicon_default.ico";
    }
}
