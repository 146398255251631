import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import { jwtDecode } from "jwt-decode";
import * as moment from "moment";

@Component({
    selector: "app-login-token",
    template: ` <p>loading...</p> `,
    styles: [],
})
export class LoginTokenComponent implements OnInit {
    constructor(
        private route: ActivatedRoute,
        private router: Router
    ) {
        const token = this.route.snapshot.paramMap.get("token");
        this.setTokenSession(token);
        this.router.navigate(["tabs"]);
    }

    ngOnInit() {
    }

    setTokenSession(token: any): void {
        try {
            const payload = <JWTPayload>jwtDecode(token);
            const expiresAt = moment.unix(payload.exp);
            localStorage.setItem("token", "Bearer " + token);
            localStorage.setItem("expires_at", JSON.stringify(expiresAt.valueOf()));
            // valid token format
        } catch (error) {
            // invalid token format
            console.error("Token Inválido");
        }
    }
}

interface JWTPayload {
    user_id: number;
    userId: string;
    username: string;
    exp: number;
}
